/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie';

const LocationLanding = dynamic(
  () => import("@/components/store-locator/location-landing")
);

import HeaderFooterContext from "@/context/HeaderFooterContext";
import { useRouter } from "next/router";
import MapCordinateContext from "@/context/MapCordinateContext";
import PageNotFound from "@/pages/404";
import dynamic from "next/dynamic";

const FacilityComponent = (props: any) => {
  const headerFooterData = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;
  const headerInfo = headerFooterData?.headerFooter[0]?.attributes;
  const [facilityInfo, setFaciltyInfo] = useState(null);
  const [articleInfo, setArticleInfo] = useState(null);
  const mapCords = useContext(MapCordinateContext);
  const facilityData = props?.facilityData;
  const { reviewsData, unitData}= props;
  const { locale }: any = useRouter();
  const isContext = !!headerFooter[0];
  let location = props?.facility;
  const [err, setErr] = useState(false);
  const getContextData = async () => {
    const brands = props?.brandData?.data;
    headerFooterData.setHeaderFooterData(brands);
  };
  const getFacilityData = async () => {
    if (locale === "fr") {
      if(props?.checkQueryLen === 2) location = `${props?.facility}-fr`
      else location = `${props?.facility}`;
    }
    let matched = false;
    if (sessionStorage.getItem("facCords")) {
      let coords = JSON.parse(sessionStorage.getItem("facCords"));
      let splicedStr;

      const frSlug = coords.find((e: any) => {
        return e.lan === "fr";
      })?.slugValue;
      const enSlug = coords.find((e: any) => {
        return e.lan === "en";
      })?.slugValue;
      const split = frSlug.split("-");
      splicedStr = split.slice(0, split.length - 1).join("-");
      if (splicedStr === props?.facility || enSlug === props?.facility) {
        if(props?.checkQueryLen === 2) matched = true;
      }

      if (matched) {
        location = coords.find((e: any) => {
          return e.lan === locale;
        })?.slugValue;
      }
    }

    const facilitySlugResData = facilityData;

     // Blog related information based on facility,city or default.
     if(facilitySlugResData?.attributes?.Blog?.data!==null && Object.keys(facilitySlugResData?.attributes?.Blog?.data)?.length) {
        setArticleInfo(facilitySlugResData?.attributes);
     } else if(facilitySlugResData?.attributes?.Blog?.data === null && facilitySlugResData?.attributes?.City?.data!==null && facilitySlugResData?.attributes?.City?.data.hasOwnProperty('attributes') && facilitySlugResData?.attributes?.City?.data?.attributes?.Blog?.data!==null){
        setArticleInfo(facilitySlugResData?.attributes?.City?.data?.attributes);
     } else {
        setArticleInfo(props?.brandData?.data[0]?.attributes);
     }
    const citySlug = facilitySlugResData?.attributes?.City_Slug;
    const multiFacility = facilitySlugResData?.attributes?.multiFacility;
    if (
      (facilitySlugResData && Object.keys(facilitySlugResData).length === 0) ||
      (props?.checkCity && !citySlug) || (multiFacility && citySlug !== props?.city) || facilitySlugResData === undefined
    ) {
      setErr(true);
    } else {
      setErr(false)
    }
    setFaciltyInfo(facilitySlugResData);

    const newArray = [
      {
        lan: facilitySlugResData?.attributes?.locale,
        slugValue: facilitySlugResData?.attributes?.Slug,
      },
      {
        lan: facilitySlugResData?.attributes?.localizations?.data[0]?.attributes
          ?.locale,
        slugValue:
          facilitySlugResData?.attributes?.localizations?.data[0]?.attributes
            ?.Slug,
      },
    ];
    if (newArray[0].lan && props?.checkQueryLen === 2) {
      sessionStorage.setItem("facCords", JSON.stringify(newArray));
      Cookies.set("facCords", JSON.stringify(newArray));
    }
    
   
  };
  useEffect(() => {
    getFacilityData();
  }, [locale, location]);
  useEffect(() => {
    getContextData();
  }, [isContext, locale]);

  useEffect(() => {
    return () => {
      mapCords.setLatLog({});
    };
  }, []);

  return (
    <>
      {facilityInfo && !err && (
        <>
          <LocationLanding
            facilityData={facilityInfo}
            brandRelatedData={headerInfo}
            articlesData={articleInfo}
            reviewsData={reviewsData}
            unitData={unitData}
          ></LocationLanding>
        </>
      )}
      {err && <PageNotFound />}
    </>
  );
};

export default FacilityComponent;
